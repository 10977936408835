<template>
    <div
        v-if="cookiesAccepted && id"
        class="vimeo-embed__container"
    >
        <figure class="vimeo-embed">
            <button
                v-if="accessibleId"
                class="vimeo-embed__accessible-switch btn--clean"
                :title="accessibleSwitchLabel"
                @click="switchVideo"
            >
                <sf-icon :icon="showAccessibleVideo ? 'visual' : 'no-visual'" />
            </button>
            <iframe
                v-show="!showAccessibleVideo"
                ref="vimeoFrame"
                frameborder="0"
                class="embed-responsive-item"
                :src="getUrl(id)"
                allow="autoplay; fullscreen"
                allowfullscreen
                :title="title || caption"
                :loading="loading"
            />
            <iframe
                v-show="showAccessibleVideo"
                ref="accessibleVimeoFrame"
                frameborder="0"
                class="embed-responsive-item"
                :src="getUrl(accessibleId)"
                allow="autoplay; fullscreen"
                allowfullscreen
                :title="title || caption"
                :loading="loading"
            />
            <figcaption
                v-if="caption"
                class="vimeo-embed__caption"
            >
                {{ caption }}
            </figcaption>
        </figure>
    </div>
    <div
        v-else
        class="vimeo-embed__blocked"
    >
        <span>We need your consent to show you this video</span>
        <sf-button
            class="dn-button--secondary"
            @click="acceptCookies"
        >
            Accept cookies
        </sf-button>
    </div>
</template>

<script>
import SfButton from '~/patterns/atoms/button/button.vue';
import SfIcon from '~/patterns/atoms/icon/icon';
// import { ONE_MILLENNIUM } from '~/utils/constants.js';

const VIMEOREGEX = /^https:\/\/vimeo\.com\/([0-9]+)$/i;
// const COOKIE_ACCESSIBLE_VIDEO = 'showAccessibleVideos';

export default {
    components: {
        SfButton,
        SfIcon
    },
    props: {
        title: {
            type: String,
            required: false,
            default: ''
        },
        url: {
            type: String,
            required: true,
            default: ''
        },
        accessibleUrl: {
            type: String,
            required: false,
            default: ''
        },
        caption: {
            type: String,
            required: false,
            default: ''
        },
        loading: {
            type: String,
            default: 'lazy'
        }
    },

    emits: [
        'play',
        'pause',
        'ended'
    ],

    data() {
        return {
            showAccessibleVideo: false,
            player: null,
            accessiblePlayer: null
            // @TODO: connect this state to the show accessible video cookie
            // showAccessibleVideo: this.$cookies.get(COOKIE_ACCESSIBLE_VIDEO)
        };
    },
    computed: {
        vimeoUrl() {
            return `https://player.vimeo.com/video/${this.showAccessibleVideo ? this.accessibleId : this.id}?title=0&byline=0&portrait=0&color=f7f5f1&dnt=1`;
        },
        id() {
            return this.getId(this.url);
        },
        accessibleId() {
            return this.getId(this.accessibleUrl);
        },
        cookiesAccepted() {
            // @TODO: connect this to the actual cookiebar
            return true;
            // return this.$cookies.get('cookie_accept_all');
        },
        accessibleSwitchLabel() {
            return this.showAccessibleVideo ? this.$t('videoWithAudioDescription') : this.$t('videoWithoutAudioDescription');
        }
    },
    mounted() {
        this.initVimeo();
    },
    methods: {
        getId(url) {
            if (url && url.match(VIMEOREGEX)) {
                const matches = url.match(VIMEOREGEX);
                return matches[1];
            }

            return null;
        },
        getUrl(id) {
            return `https://player.vimeo.com/video/${id}?title=0&byline=0&portrait=0&color=f7f5f1&dnt=1`;
        },
        switchVideo() {
            this.showAccessibleVideo = !this.showAccessibleVideo;
            this.player.pause();
            this.accessiblePlayer.pause();
            // @TODO: store show accessible video in cookie
            // this.$cookies.set(COOKIE_ACCESSIBLE_VIDEO, this.showAccessibleVideo, {
            //     maxAge: ONE_MILLENNIUM,
            //     secure: true,
            //     sameSite: 'lax'
            // });
        },
        acceptCookies() {
            const options = {
                    path: '/',
                    maxAge: 60 * 60 * 24 * 365
                },
                cookieList = [
                    { name: 'cookie_accept_all', value: '1', opts: options },
                    { name: 'cookiebar_dismissed', value: '1', opts: options }
                ];
            this.$cookies.setAll(cookieList);
            this.$router.go();
        },
        initVimeo() {
            if (!window || !window.Vimeo) {
                return;
            }

            this.player = new window.Vimeo.Player(this.$refs.vimeoFrame);
            this.accessiblePlayer = new window.Vimeo.Player(this.$refs.accessibleVimeoFrame);

            this.setupEventListeners(this.player);
            this.setupEventListeners(this.accessiblePlayer);
        },
        setupEventListeners(player) {
            player.on('play', () => {
                this.$emit('play');
            });

            player.on('pause', () => {
                this.$emit('pause');
            });

            player.on('ended', () => {
                this.$emit('ended');
            });
        }
    }
};

</script>

<style lang="less" src="./vimeo-embed.less"></style>

<i18n>
{
    "nl": {
        "videoWithAudioDescription": "Terug naar video zonder audiodescriptie",
        "videoWithoutAudioDescription": "Video met audiodescriptie bekijken"
    },
    "en": {
        "videoWithAudioDescription": "Back to video without audio description",
        "videoWithoutAudioDescription": "Watch video with audio description"
    }
}
</i18n>
